<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="row flex-nowrap justify-center">
          <HeatLabel :size="spotSize" role="cor" text="" />
          <div v-for="(colName, colIndex) in colLabels" :key="colIndex">
            <HeatLabel
              :size="spotSize"
              role="col"
              :text="colName"
              v-if="hideCols.indexOf(colName) < 0"
            />
          </div>
        </div>
        <div v-for="(rowName, rowIndex) in rowLabels" :key="rowIndex">
          <div
            class="row flex-nowrap justify-center"
            v-if="hideRows.indexOf(rowName) < 0"
          >
            <HeatLabel :size="spotSize" role="row" :text="rowName" />
            <div v-for="(colName, colIndex) in colLabels" :key="colIndex">
              <HeatSpot
                :size="spotSize"
                :background="spotBackground"
                :color="heatColor"
                :heat="values[rowIndex][colIndex]"
                :textHigh="textHigh"
                :textLow="textLow"
                :gradient="gradient"
                v-if="hideCols.indexOf(colName) < 0"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>

<script>
// import _ from 'lodash'
import HeatSpot from "@/components/HeatSpot";
import HeatLabel from "@/components/HeatLabel";

export default {
  name: "HeatMap",
  components: { HeatSpot, HeatLabel },
  computed: {
    colCount: function() {
      return this.colLabels.length;
    },
    rowCount: function() {
      return this.rowLabels.length;
    },
  },
  props: {
    spotSize: {
      type: Number,
      default: 50,
    },
    hideRows: {
      type: Array,
      default: function() {
        return [];
      },
    },
    hideCols: {
      type: Array,
      default: function() {
        return [];
      },
    },
    rowLabels: {
      type: Array,
      default: function() {
        return [""];
      },
    },
    colLabels: {
      type: Array,
      default: function() {
        return [""];
      },
    },
    values: {
      type: Array,
      default: function() {
        return [];
      },
    },
    gradient: {type: Array, default: ()=>[0.2,1]},
  },
  data: () => ({
    heatColor: [155, 81, 224],
    textHigh: "white",
    textLow: "#828282",
    spotBackground: "white",
  }),
};
</script>
