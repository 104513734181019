import Vue from 'vue'
import Vuex from 'vuex'
// import _ from 'lodash'
import logdown from 'logdown'

const logger = logdown('store')
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

// initial state
const state = () => ({
  config: {},
  userHumanName: '-',
})

// getters
const getters = {
  getConfig: state => {
    return state.config
  },
  getUserHumanName: state => {
    return state.userHumanName
  },
}

// actions
const actions = {
  // fetchAllTimes({ commit }) {
  //   logger.debug('fetchAllTimes')
  //   return fetchTimes().then(times => {
  //     commit('setAllTimes', times)
  //   })
  // },
}

// mutations
const mutations = {
  setConfig(state, newConfig) {
    logger.debug('setConfig, old', state.config)
    logger.debug('setConfig, new', newConfig)
    state.config = newConfig
  },
  setUserHumanName(state, name) {
    logger.debug('setUserHumanName', name)
    state.userHumanName = name
  },
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  strict: debug,
})