<template>
  <CardBox title="Terms" maxWidth="250">
    <template v-slot:header> </template>
    <v-list two-line subheader>
      <v-list-item
        v-for="term in terms"
        :key="term.id"
        @click="toggleTerm(term.id)"
      >
        <v-list-item-action>
          <v-checkbox :value="termsState[term.id]"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ term.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ term.termId }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CardBox>
</template>

<script>
import CardBox from "@/components/CardBox";
import { jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("TermsBox");

export default {
  name: "TermsBox",
  components: { CardBox },
  props: {
    terms: { type: Array, default: () => [] },
    selectedTerms: { type: Array, default: () => [] },
  },
  data: () => ({
    termsState: {},
  }),
  methods: {
    toggleTerm(term) {
      if (_.get(this.termsState, term)) {
        _.unset(this.termsState, term);
      } else {
        _.set(this.termsState, term, true);
      }
      logger.debug(`toggleTerm, ${jp(this.termsState)}`);
      this.$forceUpdate();
      this.$emit("termsChanged", { selectedTerms: _.keys(this.termsState) });
    },
    setState() {
      let state = {};
      _.each(this.selectedTerms, (v) => {
        state[v] = true;
      });
      this.termsState = state;
    },
  },
  mounted() {
    this.setState()
  },
  created() {
    logger.debug(`created, termsState: ${jp(this.termsState)}`);
  },
};
</script>
