import Dexie from 'dexie';
// import logdown from 'logdown'
// const logger = logdown("db");

const db = new Dexie('admindb');
db.version(1).stores({
    times: '[user+term],term,*courses',
    modalities: '[user+term+course],term,course,*modalities',
    confirmations: '[user+term],term,confirmation,*courses',
});


export default db;