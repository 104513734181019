<template>
  <v-card
    flat
    :height="calculateHeight()"
    :width="calculateWidth()"
    class="d-flex align-content-space-around flex-wrap justify-center heat-text pa-0"
    :style="`color: ${textColor};font-size: ${heatFontSize}; margin: 1px;`"
  >
    <v-container class="pa-0 ma-0">
      <v-row align="center" justify="center" no-gutters class="pa-0 ma-0">
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-0 ma-0"
        >
          {{ this.text }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.heat-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}
</style>

<script>
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("HeatLabel");

export default {
  name: "HeatSpot",
  props: {
    size: {
      type: Number,
      default: 50,
    },
    role: {
      type: String,
      default: "col",
    },
    textColor: {
      type: String,
      default: "#4F4F4F",
    },
    text: { type: String, default: "" },
  },
  computed: {
    heatBackground: function() {
      return `rgb(${this.color[0]},${this.color[1]},${this.color[2]},${this.heatGradient})`;
    },
    heatTextColor: function() {
      return this.heat > 0.25 ? this.textHigh : this.textLow;
    },
    heatFontSize: function() {
      return `${Math.round(this.size / 5)}px`;
    },
    heatText: function() {
      return this.heat < 0.01 ? "<1%" : `${Math.round(this.heat * 100)}%`;
    },
  },
  data: () => ({}),
  methods: {
    x: function(role) {
      return _.get({ col: 1, row: 2, cor: 2 }, role);
    },
    y: function(role) {
      return _.get({ col: 0.5, row: 1, cor: 0.5 }, role);
    },
    calculateHeight() {
      const height = this.size * this.y(this.role)
      logger.log(`calculateHeight: ${height}`)
      return height;
    },
    calculateWidth() {
      const width = this.size * this.x(this.role);
      logger.log(`calculateWidth: ${width}`)
      return width;
    },
  },
};
</script>
