<template>
  <v-card
    flat
    :height="calculateSize()"
    :width="calculateSize()"
    class="d-flex align-content-space-around flex-wrap justify-center heat-text pa-0"
    :style="
      `background: ${heatBackground}; color: ${heatTextColor};` +
        `font-size: ${heatFontSize};  margin: 1px;`
    "
  >
    <v-container class="pa-0 ma-0">
      <v-row align="center" justify="center" no-gutters class="pa-0 ma-0">
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-0 ma-0"
        >
          {{ this.heatText }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.heat-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}
</style>

<script>
import logdown from "logdown";
const logger = logdown("HeatSpot");

export default {
  name: "HeatSpot",
  props: {
    size: {
      type: Number,
      default: 50,
    },
    color: {
      type: Array,
      default: function() {
        return [155, 81, 224];
      },
    },
    heat: { type: Number, default: 1 },
    textHigh: { type: String, default: "white" },
    textLow: { type: String, default: "#828282" },
    gradient: { type: Array, default: () => [0.2, 1] },
  },
  computed: {
    heatGradient: function() {
      const range = this.gradient[1] - this.gradient[0];
      return this.gradient[0] + this.heat * range;
    },
    heatBackground: function() {
      return `rgb(${this.color[0]},${this.color[1]},${this.color[2]},${this.heatGradient})`;
    },
    heatTextColor: function() {
      return this.heat > 0.25 ? this.textHigh : this.textLow;
    },
    heatFontSize: function() {
      return `${Math.round(this.size / 3.5)}px`;
    },
    heatText: function() {
      return this.heat == 0
        ? ""
        : this.heat < 0.01
        ? "<1%"
        : `${Math.round(this.heat * 100)}%`;
    },
  },
  data: () => ({}),
  methods: {
    calculateSize() {
      logger.log(`calculateSize: ${this.size}`);
      return this.size;
    },
  },
};
</script>
