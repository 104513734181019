<template>
  <div class="pa-0 ma-0">
    <!-- <v-card :height="height" color="red"> -->
    <canvas :id="chartId" width="800" :height="height" v-if="chart"></canvas>
    <!-- </v-card> -->
  </div>
</template>

<script>
import { jp } from "@/utils";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("TopChart");
export default {
  name: "TopChart",
  props: {
    values: {
      type: Array,
      default: () => {
        return [];
      },
    },
    color: {
      type: Array,
      default: () => [155, 81, 224],
    },
    // chartSize: { type: Number, default: 1 },
    textHigh: { type: String, default: "white" },
    textLow: { type: String, default: "#828282" },
  },
  data: () => {
    return {
      chartValues: [],
      height: 0,
      chart: false,
      chartId: undefined,
    };
  },
  watch: {
    values: function(newVal, oldVal) {
      logger.debug("watching:", jp(newVal), jp(oldVal));
      this.chartValues = newVal;
      logger.debug("watching chartValues:", this.chartValues);
      if (this.chartValues.length > 0) {
        this.chart = true;
        logger.debug("watching chartValues > 0");
        this.height = this.canvasHeight();
        this.$forceUpdate();
        setTimeout(() => {
          this.drawChart();
        }, 0);
      } else {
        this.chart = false;
      }
    },
  },
  methods: {
    canvasHeight() {
      const itemSize = 40;
      logger.debug(`canvasHeight: ${this.chartValues.length * itemSize + 50}`);
      return this.chartValues.length * itemSize + 50;
    },
    maxVal() {
      return _.max(_.map(this.chartValues, (v) => v.value));
    },
    borderColors() {
      const maxVal = this.maxVal();
      return _.map(this.chartValues, (v) => {
        return maxVal == 0
          ? this.textHigh
          : v.value / maxVal > 0.65
          ? this.textHigh
          : this.textLow;
      });
    },
    backgroundColors() {
      const maxVal = this.maxVal();
      const bg = _.map(this.chartValues, (v) => {
        return `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, ${
          maxVal == 0 ? 0 : v.value / maxVal
        })`;
      });
      logger.debug("cv", jp(this.chartValues));
      logger.debug("bg", jp(bg));
      return bg;
    },
    onChartClick(event, elements) {
      //   logger.debug(jdump(event));
      //   logger.debug(`event:`, event);
      //   logger.debug(`elements:`, elements[0]);
      const index = _.get(elements, [0, "_index"]);
      const label = _.get(this.chartValues, index)
      logger.debug('label', label)
      this.$emit('chart-click', _.get(this.chartValues, index))
    //   logger.debug(`elements:`, _.get(this.chartValues, [index]));
    //   logger.debug(`chartValues:`, this.chartValues);
    },
    drawChart() {
      //   logger.debug("drawChart, chartValues:", jp(this.chartValues));
      const ctx = document.getElementById(this.chartId);
      logger.debug(`drawChart, ${ctx}, chartValues:`, jp(this.chartValues));
      if (ctx && this.chartValues.length > 0) {
        var myChart = new Chart(ctx, {
          type: "horizontalBar",
          plugins: [ChartDataLabels],
          data: {
            labels: _.map(this.values, (v) => v.label),
            datasets: [
              {
                label: "# of Reservations",
                data: _.map(this.values, (v) => v.value),
                backgroundColor: this.backgroundColors(),
                borderColor: this.borderColors(),
                borderWidth: 1,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                align: "start",
                anchor: "end",
                color: function(context) {
                  return context.dataset.borderColor;
                },
                formatter: function(value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
                listeners: {
                  click: function(context) {
                    // Receives `click` events only for labels of the first dataset.
                    // The clicked label index is available in `context.dataIndex`.
                    console.log(
                      "label " + context.dataIndex + " has been clicked!"
                    );
                  },
                },
              },
            },
            legend: { display: false },
            scales: {
              xAxes: [
                {
                  ticks: {
                    //   suggestedMin: 0,
                    beginAtZero: true,
                    display: true, //false,
                    offset: true,
                  },
                },
              ],
            },
            events: ["click"],
            onClick: this.onChartClick,
          },
        });
        logger.debug("myChart", myChart);
      }
    },
  },
  mounted(){
    this.chartId = `topChart-${this._uid}`
  }
};
</script>
