/**
 * Mocking the Times related API
*/

import axios from 'axios'
import logdown from 'logdown'
const logger = logdown("config");
import _ from 'lodash'

const _version = require('../../public/version.json')

const _domain = _.get(window, ['location', 'hostname'])
const _protocol = _.get(window, ['location', 'protocol'])
const _app_root = _protocol + "//" + _domain
const _api_root = _app_root + ("localhost" == _domain ? ":8090" : '') + "/api"

logger.debug(`client domain: ${_domain}, api root: ${_api_root}`)

function appRoot() { return _app_root }
function apiRoot() { return _api_root }

const _config = {
  build: '',
  versionCheckInterval: 60000 * 3,
  defaultTimeZone: 'America/Los_Angeles',
  terms: [
    // // {
    // //   id: '2021Summer', termId: '202130', season: 'summer', reservationLimit: 2,
    // //   title: "Summer 2021",
    // //   info: "May 17 - Aug 14",
    // //   begins: "February 2021",
    // // },
    // // {
    // //   id: '2021Fall', termId: '202210', season: 'fall', reservationLimit: 2,
    // //   title: "Fall 2021",
    // //   info: "Aug 23 - Dec 17",
    // //   begins: "February 2021",
    // // },
    // {
    //   id: '2021Winter', termId: '202160', season: 'winter', reservationLimit: 2,
    //   title: "Winter 2021-2022",
    //   info: "Jan 10 - May 6",
    //   begins: "October 2021",
    // },
    // {
    //   id: '2022Spring', termId: '202220', season: 'spring', reservationLimit: 2,
    //   title: "Spring 2022",
    //   info: "Jan 10 - May 6",
    //   begins: "October 2020",
    // },
    ////////////////////////////
    {
      id: '2021Winter', termId: '202110', season: 'spring', reservationLimit: 2,
      title: "Winter 2021",
      info: "",
      begins: "--",
    },
    {
      id: '2021Spring', termId: '202130', season: 'spring', reservationLimit: 2,
      title: "Spring 2021",
      info: "",
      begins: "--",
    },
    {
      id: '2021Summer', termId: '202140', season: 'summer', reservationLimit: 2,
      title: "Summer 2021",
      info: "",
      begins: "--",
    },
    {
      id: '2021Fall', termId: '202150', season: 'fall', reservationLimit: 2,
      title: "Fall 2021",
      info: "",
      begins: "--",
    },
    {
      id: '2022Winter', termId: '202210', season: 'spring', reservationLimit: 2,
      title: "Winter 2022",
      info: "",
      begins: "--",
    },
    {
      id: '2022Spring', termId: '202230', season: 'spring', reservationLimit: 2,
      title: "Spring 2022",
      info: "",
      begins: "--",
    },
    {
      id: '2022Summer', termId: '202240', season: 'summer', reservationLimit: 2,
      title: "Summer 2022",
      info: "",
      begins: "--",
    },
    {
      id: '2022Fall', termId: '202250', season: 'fall', reservationLimit: 2,
      title: "Fall 2022",
      info: "",
      begins: "--",
    },
    {
      id: '2023Winter', termId: '202210', season: 'winter', reservationLimit: 2,
      title: "Winter 2023",
      info: "",
      begins: "--",
    },
  ],
  validTerms: { '2021Summer': '202130;202140', '2021Fall': '202210', '2021Winter': '202160', '2022Spring': '202220' },
  validModalities: ["In person", "Hybrid", "Online"],
  timeSlots: {
    days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    times: [
      "0:00a", "1:00a", "2:00a", "3:00a", "4:00a", "5:00a",
      "6:00a", "7:00a", "8:00a", "9:00a", "10:00a", "11:00a",
      "12:00p", "1:00p", "2:00p", "3:00p", "4:00p", "5:00p",
      "6:00p", "7:00p", "8:00p", "9:00p", "10:00p", "11:00p",],
    excludeDays: [], //["Sun"],
    unselectDays: [], //["Sat", "Sun"],
    excludeTimes: ["0:00a", "1:00a", "2:00a", "3:00a", "11:00p"],
    unselectTimes: ["3:00a", "4:00a", "5:00a", "6:00a", "7:00p", "8:00p", "9:00p", "10:00p"],
    // unselectDayTimes: ["Mon-6:00p", "Fri-6:00p"],
  },
  heatMap: {
    defaultTimeLabels: [
      "0:00a",
      "1:00a",
      "2:00a",
      "3:00a",
      "4:00a",
      "5:00a",
      "6:00a",
      "7:00a",
      "8:00a",
      "9:00a",
      "10:00a",
      "11:00a",
      "12:00p",
      "1:00p",
      "2:00p",
      "3:00p",
      "4:00p",
      "5:00p",
      "6:00p",
      "7:00p",
      "8:00p",
      "9:00p",
      "10:00p",
      "11:00p",
    ],
    defaultDayLabels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  }
}

function fetchConfig() {
  return new Promise((resolve) => {
    logger.debug('fetchConfig, returning config', _.assign(_config, _version))
    setTimeout(resolve(_config), 0)
  })
}

function latestVersion() {
  return axios.get(`/version.json?t=${(new Date()).getTime()}`).then((response) => {
    logger.debug('latestVersion', response.data)
    try {
      return _.get(response.data, ['build'])
    } catch (err) {
      return undefined
    }
  })
}

const _versionCheckInterval = 5000;

/**
 * 
 * @param {*} interval 
 * @param {*} currentVersion 
 * @param {*} failCallBack 
 */
function startVersionCheck(interval, currentVersion, failCallBack, networkErrorCallBack) {
  setInterval(() => {
    latestVersion()
      .then((version) => {
        logger.debug(`latestVersion: ${version}, currentVersion: ${currentVersion}`);
        if (version != currentVersion) {
          failCallBack(version, currentVersion)
        }
      })
      .catch((err) => {
        logger.debug(`latestVersion ${err}`);
        if (networkErrorCallBack) {
          networkErrorCallBack()
        }
      });
  }, (interval) ? interval : _versionCheckInterval);
}

function translateTerm(term, terms) {
  return _.get(_.find(terms, (v) => term == _.get(v, 'id')), 'termId')
}

export {
  fetchConfig,
  startVersionCheck,
  apiRoot,
  appRoot,
  translateTerm,
}