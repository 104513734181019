<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="EdReserve Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-white.svg')"
          transition="scale-transition"
          width="120"
        />
      </div>
      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:extension>
        <v-tabs v-model="tab" align-with-title show-arrows>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-tabs v-model="tab" align-with-title show-arrows>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-tabs-items v-model="tab" v-if="displayUI">
        <v-tab-item>
          <Times :key="lastUserUpdate" @download="onDownload" />
        </v-tab-item>
        <v-tab-item>
          <Modalities :key="lastUserUpdate" @download="onDownload" />
        </v-tab-item>
        <v-tab-item>
          <Users :key="lastUserUpdate" @download="onDownload" />
        </v-tab-item>
        <v-tab-item>
          <Feedback @download="onDownload" />
        </v-tab-item>
        <v-tab-item>
          <Promo @download="onDownload" />
        </v-tab-item>
      </v-tabs-items>
      <Splash v-if="screen == 'Splash'" />
      <v-snackbar v-model="messageBar" :multi-line="multiLineMessage" top>
        {{ messageBarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="messageBar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-overlay :value="dataRefresh">
        <v-progress-circular indeterminate size="64">{{
          progressValue
        }}</v-progress-circular>
      </v-overlay>
      <v-dialog v-model="downloadDialog" persistent max-width="290"
        ><v-card>
          <v-card-title class="headline">
            Download
          </v-card-title>
          <v-card-text
            ><a :download="downloadDataFile" :href="downloadData">{{
              downloadDataFile
            }}</a></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="downloadDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card></v-dialog
      >
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { downloadUserData } from "@/api/userdata";

import Times from "@/components/Times";
import Promo from "@/components/Promo";
import Feedback from "@/components/Feedback";
import Users from "@/components/Users";
import Modalities from "@/components/Modalities";
import Splash from "@/components/Splash";
import { fetchConfig, startVersionCheck } from "@/api/config.js";
import { reload, getUserId, getUserHumanName, isTestHostname } from "@/utils";
import _ from "lodash";
import logdown from "logdown";

const logger = logdown("App");

export default {
  name: "App",
  data: () => {
    return {
      tab: null,
      items: [
        "times",
        "modalities",
        "users",
        "feedback", 
        //"promo"
      ],
      screen: "Splash",
      messageBar: false,
      messageBarText: "",
      multiLineMessage: true,
      dataRefresh: false,
      progressValue: 0,
      lastUserUpdate: new Date().getTime(),
      downloadDialog: false,
      downloadDataFile: "",
      downloadData: "",
      displayUI: false,
    };
  },
  components: {
    Times,
    Users,
    Modalities,
    Promo,
    Feedback,
    Splash,
  },

  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
  },

  methods: {
    ...mapMutations({
      setConfig: "setConfig",
      setUserHumanName: "setUserHumanName",
    }),
    onDownload(event) {
      this.downloadDataFile = event.fileName;
      this.downloadData = event.data;
      this.downloadDialog = true;
    },
    onDBRefresh() {
      this.dataRefresh = true;
      downloadUserData(this.config, (progress) => {
        this.progressValue = progress;
      })
        .then(
          ({
            recordCount,
            modalitiesCount,
            timesCount,
            confirmationsCount,
          }) => {
            this.dataRefresh = false;
            this.messageBarText = `user records fetched: ${recordCount}, modalities: ${modalitiesCount}, times: ${timesCount}, confirmations: ${confirmationsCount}`;
            this.messageBar = true;
            // this.updateHeatMap();
            this.lastUserUpdate = new Date().getTime();
          }
        )
        .catch((e) => {
          logger.debug(e);
          this.messageBarText = `something went wrong: ${e}`;
          this.messageBar = true;
        });
    },
  },
  created() {
    logger.debug("created");
    const userId = getUserId();
    const userHumanName = getUserHumanName();
    logger.debug(`userId: '${userId}', userHumanName: '${userHumanName}'`);
    this.setUserHumanName(userHumanName);
    if (!userId) {
      if (!isTestHostname(window.location.hostname)) {
        window.location.replace("/sso/login");
      } else {
        logger.debug("localhost will not redirect");
        this.screen = "";
      }
    } else {
      fetchConfig()
        .then((config) => {
          logger.debug("config:", config);
          this.setConfig(config);
          startVersionCheck(
            _.get(this.config, "versionCheckInterval"),
            _.get(this.config, "build"),
            (newVersion, oldVersion) => {
              logger.debug(`version check:${newVersion}, ${oldVersion}`);
              reload();
            },
            () => {
              logger.debug("network error callback");
            }
          );
        })
        .then(() => {
          this.onDBRefresh();
        })
        .then(() => {
          this.displayUI = true;
          this.screen = "Times";
        })
        .catch((e) => {
          logger.error(e);
        });
    }
  },
};
</script>
