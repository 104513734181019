import _ from 'lodash'
import Bowser from "bowser";
import logdown from 'logdown'
import { decode as decodeBase64 } from 'js-base64';
import stringify from 'csv-stringify/lib/sync'
import scrollIntoView from 'scroll-into-view'
const logger = logdown('utils')

// API: isFullScreen, enterFullScreen, exitFullScreen

function getAuthKey() {
  try {
    return _.get(JSON.parse(localStorage.getItem('x-session-data')), ['localKey']) || ''
  } catch (err) {
    console.log(`getAuthKey: could not get`)
  }
}

function decodeAuthKey(encodedKey) {
  try {
    const key = _.get(_.split(encodedKey, ':'), [0])
    logger.debug('decodeAuthKey, key', key)
    return JSON.parse(decodeBase64(key))
  } catch (err) {
    console.log(`decodeAuthKey: could not decode`)
  }
}

function getUserId() {
  return _.get((decodeAuthKey(getAuthKey()) || {}), 'user')
}

function getUserStore() {
  return 'uid-' + getUserId()
}

function getUserHumanName() {
  const fn = _.get((decodeAuthKey(getAuthKey()) || {}), 'firstName')
  const ln = _.get((decodeAuthKey(getAuthKey()) || {}), 'lastName')
  if (fn) {
    return `${fn} ${(ln) ? ln.substring(0, 1) : ''}.`
  }
}

function logout() {
  logger.debug('logout')
  localStorage.removeItem('x-session-data')
  window.location.reload()
}

function reload() {
  logger.debug('reload')
  window.location.replace(`/#${(new Date()).getTime()}`)
  window.location.reload()
}

const _browser = (() => {
  const agent = _.get(window, ['navigator', 'userAgent'])
  try {
    const info = Bowser.parse(agent)
    logger.debug('browser', info)
    return info
  } catch (err) {
    logger.debug('browser not detected, using stub')
    return {
      browser: { name: "unknown", version: "x.x" },
      os: { name: "unknown", version: "x.x", versionName: "x.x", },
      platform: { type: "unknown" },
      engine: { name: "unknown", version: "x.x" }
    }
  }
})();

function getBrowserInfo() {
  return _browser;
}

function getBrowser() {
  const info = {
    name: _browser.browser.name,
    version: _browser.browser.version,
    os: _browser.os.name,
  }
  logger.debug(`reporting browser`, info)
  return info;
}

function isTestHostname(hostname) {
  return /^(localhost|([0-9]+\.){3,}([0-9])+)$/ig.test(hostname || '')
}

function scrollElementIntoView(el) {
  scrollIntoView(el, { align: { top: 0, left: 0 } })
}

function jp(v) {
  return JSON.stringify(v);
}

function toCSV(columns, array, transform) {
  const data = _.map(array, item =>
    _.map(columns, key => _.get(transform ? transform(item) : item, key))
  )
  data.unshift(columns)
  return stringify(data)
}

function toJSON(obj) {
  var cache = [];
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (cache.includes(value)) return;
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
}

export {
  getAuthKey,
  decodeAuthKey,
  getUserId,
  getUserHumanName,
  getUserStore,
  logout,
  reload,
  getBrowser,
  getBrowserInfo,
  isTestHostname,
  scrollElementIntoView,
  jp,
  toCSV,
  toJSON,
}