<template>
  <v-card :max-width="maxWidth" :min-width="minWidth" class="mx-auto">
    <v-toolbar :color="barColor" dark dense short>
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-toolbar-title class="subtitle-1">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="header"></slot>
    </v-toolbar>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "CardBox",
  props: {
    barColor: { type: String, default: "#4F4F4F" },
    title: { type: String, default: "" },
    maxWidth: { type: String, default: "375" },
    minWidth: { type: String, default: "175" },
  },
};
</script>
