<template>
  <v-container>
    <v-row>
      <v-col md="12" lg="9" xl="10">
        <CardBox title="All promo refs" minWidth="300" maxWidth="1800">
          <template v-slot:header>
            <v-icon @click="onDownloadPromoData">mdi-file-download</v-icon>
            <v-icon @click="updatePromoData">mdi-database-sync</v-icon>
          </template>

          <v-card>
            <v-card-title>
              <v-text-field
                v-model="promoTableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="promoTableHeaders"
              :items="promoTableItems"
              :search="promoTableSearch"
              :single-select="false"
              item-key="user"
              v-model="promoTableSelection"
            >
            </v-data-table>
          </v-card>
        </CardBox>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import CardBox from "@/components/CardBox";
import { mapGetters } from "vuex";
import { downloadPromoData } from "@/api/userdata";
import { toCSV } from "@/utils";
// import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Promo");

export default {
  name: "Times",
  components: {
    CardBox,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
    promoTableHeaders() {
      return [
        { text: "student", value: "user", filterable: true },
        { text: "code", value: "code", filterable: true },
        { text: "count", value: "count", filterable: true },
        { text: "refs", value: "refs", filterable: true },
      ];
    },
  },

  data: () => ({
    promoTableItems: [],
    promoTableSearch: "",
    promoTableSelection: [],
  }),

  methods: {
    onDownloadPromoData() {
      const columns = ['user','code','count','refs']
      this.$emit('download',{
        data: `data:text/plain,${toCSV(columns,this.promoTableItems)}`,
        fileName: `promo-${((new Date()).toISOString()).split('T')[0]}.csv`
      })
    },
    updatePromoData() {
      downloadPromoData().then((data) => {
        // queryPromoData().then((data) => {
        this.promoTableItems = data;
        // });
      });
    },
  },

  created() {
    logger.debug("created");
  },
};
</script>
