<template>
  <v-container>
    <v-row>
      <v-col md="12" lg="3" xl="2">
        <TermsBox
          :terms="config.terms"
          :selectedTerms="selectedTerms"
          @termsChanged="onTermsChanged"
        />
      </v-col>
      <v-col md="12" lg="9" xl="10">
        <CardBox title="All reservations" minWidth="300" maxWidth="1800">
          <template v-slot:header>
            <v-icon @click="onDownloadUsersData">mdi-file-download</v-icon>
          </template>

          <v-card>
            <v-card-title>
              <v-text-field
                v-model="studentTableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="studentTableHeaders"
              :items="studentTableItems"
              :search="studentTableSearch"
              :single-select="false"
              item-key="course"
              v-model="studentTableSelection"
            >
            </v-data-table>
          </v-card>
        </CardBox>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import CardBox from "@/components/CardBox";
import TermsBox from "@/components/TermsBox";
import { mapGetters } from "vuex";
import { queryUserConfirmations } from "@/api/userdata";
import { translateTerm } from "@/api/config";
import { toCSV, jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Users");

export default {
  name: "Times",
  components: {
    CardBox,
    TermsBox,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
    studentTableHeaders() {
      return [
        { text: "student", value: "user", filterable: true },
        {
          text: "term",
          value: "term",
          filter: (v) => {
            let translated = _.map(this.selectedTerms, (v) =>
              translateTerm(v, this.config.terms)
            );
            return _.indexOf(translated, v) >= 0;
          },
        },
        { text: "confirmation", value: "confirmation", filterable: true },
        { text: "courses", value: "courses" },
      ];
    },
  },

  data: () => ({
    selectedTerms: [
      // "2021Winter", "2021Spring", "2021Summer", "2021Fall", 
      "2022Winter", "2022Spring", "2022Summer", "2022Fall", 
      "2023Winter"
      ],
    // tranlsatedSelectedTerms: [],
    studentFilter: undefined,
    studentTableItems: [],
    studentTableSearch: "",
    studentTableSelection: [],
  }),

  methods: {
    onDownloadUsersData() {
      const columns = ["user", "term", "confirmation", "courses"];
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, this.studentTableItems)}`,
        fileName: `users-${new Date().toISOString().split("T")[0]}.csv`,
      });
    },
    onTermsChanged(event) {
      this.selectedTerms = _.get(event, "selectedTerms");
      logger.debug(`onTermsChanged: ${jp(this.selectedTerms)}`);
    },
    updateControls() {
      // this.updateHeatMap();
      // this.updateTopCourseChart();
    },
    updateUserConfirmations() {
      queryUserConfirmations().then((data) => {
        this.studentTableItems = _.map(data, (v) => ({
          confirmation: _.get(v, "confirmation"),
          courses: _.get(v, "courses"),
          term: translateTerm(_.get(v, "term"), this.config.terms),
          user: _.get(v, "user"),
        }));
        // this.studentTableItems = data;
      });
    },
  },

  created() {
    logger.debug("created");
    this.updateUserConfirmations();
  },
};
</script>
