<template>
  <v-container>
    <v-row>
      <v-col md="12" lg="3" xl="2">
        <CardBox title="Rating">
          <v-card flat class="align-center justify-center">
            <v-card-title>{{ rating }}</v-card-title>
          </v-card>
        </CardBox>
      </v-col>

      <v-col md="12" lg="9" xl="10">
        <CardBox title="All feedback" minWidth="300" maxWidth="1800">
          <template v-slot:header>
            <v-icon @click="onDownloadFeedbackData">mdi-file-download</v-icon>
            <v-icon @click="updateFeedbackData">mdi-database-sync</v-icon>
          </template>

          <v-card>
            <v-card-title>
              <v-text-field
                v-model="feedbackTableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="feedbackTableHeaders"
              :items="feedbackTableItems"
              :search="feedbackTableSearch"
              :single-select="false"
              v-model="feedbackTableSelection"
            >
            </v-data-table>
          </v-card>
        </CardBox>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import CardBox from "@/components/CardBox";
import { mapGetters } from "vuex";
import { downloadFeedbackData } from "@/api/userdata";
import {
  toCSV,
  // jp,
} from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Feedback");

export default {
  name: "Times",
  components: {
    CardBox,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
    feedbackTableHeaders() {
      return [
        { text: "student", value: "user", filterable: true },
        { text: "time", value: "time", filterable: true },
        { text: "mode", value: "mode", filterable: true },
        { text: "rating", value: "rating", filterable: true },
        { text: "text", value: "text", filterable: true },
      ];
    },
  },

  data: () => ({
    feedbackTableItems: [],
    feedbackTableSearch: "",
    feedbackTableSelection: [],
    rating: "refresh the data",
  }),

  methods: {
    onDownloadFeedbackData() {
      const columns = ["user", "time", "mode", "rating","text"];
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, this.feedbackTableItems)}`,
        fileName: `feedback-${new Date().toISOString().split("T")[0]}.csv`,
      });
    },
    calculateRating() {
      let values = _.map(
        _.filter(
          this.feedbackTableItems,
          (item) => _.get(item, "mode") == "finish" && _.get(item, "rating") > 0
        ),
        (v) => _.get(v, "rating")
      );
      this.rating =
        Math.round(
          (values.length > 0 ? _.sum(values) / values.length : 0) * 100
        ) / 100;
    },
    updateFeedbackData() {
      downloadFeedbackData().then((data) => {
        // queryPromoData().then((data) => {
        this.feedbackTableItems = data;
        this.calculateRating();
        // });
      });
    },
  },

  created() {
    logger.debug("created");
  },
};
</script>
